import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`IVRs allow customers to navigate through a menu of a series of pre-recorded messages and options. IVRs help customers to get the information they need and to perform actions like paying bills, checking account balances, or making appointments withou the intervention of a human operator.`}<br /><br />{`IVR Nodes panels list information and metrics associated with IVRs that can include the DTMF Success Rate, IVR name, amond others.`}</p>
    <p>{`The following tables list the metrics of IVR metric panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forwardings`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><b>{`# - Count`}</b></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Instances`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><b>{`# - Count`}</b></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      